const socialLinks = [
    {
        url: 'https://www.instagram.com/mariliamariano_/',
        label: 'Instagram icon',
        icon: 'uil uil-instagram',
    },
    {
        url: 'https://wa.me/5584996130349',
        label: 'Whatsapp Icon',
        icon: 'uil uil-whatsapp',
    },
    
];

export default socialLinks;

