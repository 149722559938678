import React, { useEffect } from "react";
import "./Yogamente.css";
import MainBtn from "../MainBtn/MainBtn";
import Formulario from "../formulario/Formulario";
import Mapa2 from "../mapa2/Mapa2";
import Pdd from "../pdd/Pdd";

function Yogamente() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="yoga-wrapper">
      <div className="yoga-container">
        <div className="yoga-content">
          <br /><br /><br />
          <h2>Yoga Restaurativa</h2>
          <div className="yoga-infos">
            <div className="yoga-text">
              <p>
                Vivencie afeto e compreensão do próprio corpo como um templo que
                abriga a essência e amplia a consciência para reflexões internas. 
                Acompanhe também o Instagram{" "}
                <a id="linkinstapdd" href="https://www.instagram.com/yogaparquedasdunas/" target="_blank" rel="noopener noreferrer">
                @clinica.casazen 
                </a>.
                <br />
                <br />
                <strong>Duração:</strong> 1h 30min
                <br />
                <strong>Dias:</strong> Terças e Quintas
                <br />
                <strong>Horários:</strong> das 7h 30min às 9h
              </p>
            </div>
            <div className="yoga-map">
              <Mapa2 />
            </div>
          </div>
          <div className="tenho-interesse-form">
            <Formulario />
          </div>
          <Pdd />
        </div>
      </div>
    </div>
  );
}

export default Yogamente;
