import React from 'react';
import './CTA1.css';
import MainBtn from '../MainBtn/MainBtn';
import marilia from "../../assets/imgs/mariliainicio.png";
import { InstagramEmbed } from "react-social-media-embed";

function CTA1() {
  return (
    <div className="instaFeed-wrapper">
    <div className="instaFeed-container">
      <h2>Acompanhe no Instagram</h2>
      <h4>Fique por dentro de eventos, aulas e novidades!</h4>
      <div className="instaFeed-content">
        <div>
          <InstagramEmbed
            url="https://www.instagram.com/p/DBzBbkQJ58T/"
            width={328}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/C-dGHRMOm4s/"
            width={328}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/C7FehwMLPKe/"
            width={328}
          />
        </div>
        <div>
          <InstagramEmbed
            url="https://www.instagram.com/p/DBXsITLNbyr/"
            width={328}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/DBXKi9MSxsl/"
            width={328}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/C7aFxOWuEZF/?img_index=1"
            width={328}
          />
        </div>
      </div>
    </div>
  </div>
  );
}

export default CTA1;
