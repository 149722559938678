import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Especialidades.css";
import Yogamente from "../../components/yogamente/Yogamente";
import Sala from "../../components/saladescanso/Sala";

function Especialidades() {
  const navigate = useNavigate();
  const [aulaSelecionada, setAulaSelecionada] = useState(null);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePageChange = (aula) => {
    scrollToTop();
    setTimeout(() => {
      if (aula === 1) {
        navigate("/yogamente");
      } else if (aula === 2) {
        navigate("/saladescanso");
      }
    }, 500); // Atraso para garantir que o scroll ocorra antes da navegação
  };

  return (
    <div className="especialidades-wrapper">
      <div className="especialidades-container">
        {/* Aula YogaMente */}
        <div className="aula-item">
          <h2>Yoga Restaurativa</h2>
          <p>
            É a junção de posturas leves a moderadas de Hatha Yoga com as
            colunas do Método de Yoga Restaurativo: tempo, toque, afeto e
            diálogo. Minha proposta é trabalhar as colunas: tempo e diálogo em
            rodas de conversas diante das experiências vivenciadas em prática.
          </p>
          <button onClick={() => handlePageChange(1)}>Saiba Mais</button>
        </div>

        {/* Aula Sala de Descanso */}
        <div className="aula-item">
          <h2>Yoga Aromático</h2>
          <p>
            Convido você a participar de uma experiência única que une as
            práticas do yoga e da aromaterapia em um evento transformador. Neste
            encontro especial, iremos explorar o poder dos óleos essenciais em
            sinergia com as posturas de yoga, criando uma jornada profunda de
            autoconhecimento e equilíbrio emocional.
          </p>
          <button onClick={() => handlePageChange(2)}>Saiba Mais</button>
        </div>
      </div>

      {/* Renderização condicional, se necessário */}
      <div className="servicos-grid">
        {aulaSelecionada === 1 && <Yogamente />}
        {aulaSelecionada === 2 && <Sala />}
      </div>
    </div>
  );
}

export default Especialidades;
