import React from "react";
import "./SobreAIntrurora.css";
import MainBtn from "../MainBtn/MainBtn";
import marilia6 from "../../assets/imgs/marilia6.jpg";

function SobreAIntrurora() {
  return (
    <div className="sobreaintrurora-wrapper">
      <div className="sobreaintrurora-container">
        <div className="sobreaintrurora-content">
          <h2>Sobre mim</h2>
          <br />

          <ul>
            <li>
              <h4>Minha jornada</h4>
              <br />
              <p>
                Iniciei Yoga aos 46 anos buscando mobilidade física, mas logo
                percebi que a prática transcendia o corpo, envolvendo um estado
                de consciência no presente. Minha trajetória incluiu Reiki, Yoga
                Massagem Ayurvédica, Aromaterapia Vibracional, Hatha Yoga,
                Alimentação Natural, e Terapia Restaurativa.
              </p>
            </li>
            <li>
              <br />
              <p>
                Encontrei exaustão acumulada em meu ‘SER’ por ciclos de vida,
                permitindo-me transformar hábitos e encontrar refúgio no
                conhecimento diário, renovando-me constantemente em grupos de
                apoio e rodas de conversa.
              </p>
            </li>
            <li>
              <br /><p>
                Como mulher e mãe, busquei silêncio interno para nutrir meu
                ‘SER’. As mudanças foram profundas e permanentes, revelando-se
                através de reflexões e ciclos de vida, integrando amorosidade e
                respeito em cada nova etapa.
              </p>
              
            </li>
          </ul>
        </div>
        <div className="sobreaintrurora-img">
          <img src={marilia6} alt="" />
        </div>
      </div>

      <div className="flexfrase">
        <br />
        <br />
      </div>
    </div>
  );
}

export default SobreAIntrurora;
