import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation, BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import Hero from "./components/hero/Hero";
import menuItems from "./components/shared/data/menuItems";
import socialLinks from "./components/shared/data/socialLinks";
import handleNavigationBtn from "./components/shared/utils/handleNavigationBtn";

// Importação dos componentes
import Index from "./components/index/Index";
import Incio from "./components/incio/Incio";
import Aulas from "./components/aulas/Aulas";
import ModalidadesOnline from "./components/modalidadesonline/ModalidadesOnline";
import Contatos from "./components/contatos/Contatos";
import TenhoInteresse from "./components/tenhointeresse/TenhoInteresse";
import Especialidades from "./components/especialidades/Especialidades"; // Adicione a importação do Especialidades
import Experiencia from "./components/experiencia/Experiencia";
import SobreAIntrurora from "./components/sobreaintrurora/SobreAIntrurora";
import CTA1 from "./components/cta1/CTA1";
import Yogamente from "./components/yogamente/Yogamente.js";
import Sala from "./components/saladescanso/Sala";
import TerapiaRestaurativa from "./components/terapiaresaturativa/TerapiaRestaurativa.js";
import MahaLilah from "./components/mahalilah/Mahalilah";
import AulasEmpresas from "./components/aulasempresas/AulasEmpresas";
import Linktree from './components/linktree/linktree.js'

import "./App.css";
import "./assets/styles/Reset.css";

function App() {
  const main = process.env.REACT_APP_MAIN;
  const navigate = useNavigate();
  const location = useLocation();
  const [refs, setRefs] = useState({});

  useEffect(() => {
    const setupRefs = () => {
      const newRefs = {};
      menuItems.forEach((item) => {
        if (item.type === "Scroll") {
          newRefs[item.to] = React.createRef();
        }
      });
      setRefs(newRefs);
    };
    setupRefs();
  }, [menuItems]);

  const handleNavigation = async (item) => {
    await handleNavigationBtn(item, refs, navigate, location);
  };

  // Verifique se a rota atual é "/link"
  const isLinkRoute = location.pathname.includes('/links');

  return (
    <div className="App">
      {!isLinkRoute && (
        <Header
          handleNavigationBtn={handleNavigation}
          menuItems={menuItems}
          socialLinks={socialLinks}
        />
      )}
      <Routes>
        <Route
          path={main}
          element={
            <>
              <div className="body-wrapper">
                <Hero />
                <div ref={refs.aulas}>
                  <Aulas />
                </div>
                {/*  <div ref={refs.incio}> */}
                {/* <Incio /> */}
                {/* </div> */}
                <div ref={refs.modalidadesonline}>
                  <ModalidadesOnline />
                  <CTA1 />
                </div>
                <div ref={refs.sobreaintrutora}>
                  <SobreAIntrurora />
                </div>
                {/* <div ref={refs.tenhointeresse}>
                  <TenhoInteresse />
                </div>
                <div ref={refs.contatos}>
                  <Contatos />
                </div> */}
              </div>
            </>
          }
        />
        {/* Adicione a rota para o componente Especialidades */}
        <Route path="/especialidades" element={<Especialidades />} />
        <Route path="/yogamente" element={<Yogamente />} />
        <Route path="/saladescanso" element={<Sala />} />
        <Route path="/terapia" element={<TerapiaRestaurativa />} />
        <Route path="/mahalilah" element={<MahaLilah />} />
        <Route path="/aulasparaempresas" element={<AulasEmpresas />} />
        <Route path="/links" element={<Linktree />} /> {/* Rota do Linktree */}
      </Routes>
      {!isLinkRoute && (
        <Footer
          handleNavigationBtn={handleNavigation}
          menuItems={menuItems}
          socialLinks={socialLinks}
        />
      )}
      <div className="whats-btn">
        <a href="https://wa.me/5584996130349" target="_blank" rel="noreferrer">
          <i className="uil uil-whatsapp whats"></i>
        </a>
      </div>
    </div>
  );
}

export default App;
