import React from "react";
import "./Aulas.css";
import Especialidades from "../especialidades/Especialidades";


function Aulas() {
  return (
    <div className="aulas-wrapper">
      <div className="aulas-container">
        <div className="aulas-content">
          <h2>Aulas</h2>
          <br />
      
          <Especialidades />
          
        
          <br />
          <br />
          {/* <MainBtn link='https://wa.me/5584996130349' alt={true} children="Saiba mais sobre todos os serviços" /> */}
        </div>
      </div>
    </div>
  );
}

export default Aulas;
