import React, { useState } from 'react';
import './Formulario.css';
import MainBtn from '../MainBtn/MainBtn';

function Formulario() {
  const [formData, setFormData] = useState({
    nome: '',
    contato: '',
    dataNascimento: '',
    email: '',
    praticouYoga: '',
    condicaoMedica: '',
    medicacao: '',
    objetivo: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { nome, contato, dataNascimento, email, praticouYoga, condicaoMedica, medicacao, objetivo } = formData;
    const mensagem = `
      Nome: ${nome}
      Contato: ${contato}
      Data de nascimento: ${dataNascimento}
      E-mail: ${email}
      Já praticou Yoga? Se sim, qual a modalidade? E por quanto tempo?: ${praticouYoga}
      Possui alguma condição médica ou problema não mencionado?: ${condicaoMedica}
      Tomar alguma medicação?: ${medicacao}
      Qual o seu objetivo na busca pelo Yoga?: ${objetivo}
    `.trim().replace(/\n/g, '%0A');

    const url = `https://wa.me/5584996130349?text=${encodeURIComponent(mensagem)}`;
    window.open(url, '_blank');
  };

  return (
    <div className='formulario-wrapper'>
      <div className='formulario-container'>
        <div className='formulario-content'>
          <h2>Formulário de interesse</h2>
          <br />
          <br />
          <form onSubmit={handleSubmit}>
            <div className='form-group'>
              <label htmlFor='nome'>Nome:</label>
              <input
                type='text'
                id='nome'
                name='nome'
                value={formData.nome}
                onChange={handleChange}
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='contato'>Contato:</label>
              <input
                type='text'
                id='contato'
                name='contato'
                value={formData.contato}
                onChange={handleChange}
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='dataNascimento'>Data de nascimento:</label>
              <input
                type='date'
                id='dataNascimento'
                name='dataNascimento'
                value={formData.dataNascimento}
                onChange={handleChange}
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='email'>E-mail:</label>
              <input
                type='email'
                id='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='praticouYoga'>Já praticou Yoga? Se sim, qual a modalidade? E por quanto tempo?</label>
              <textarea
                id='praticouYoga'
                name='praticouYoga'
                value={formData.praticouYoga}
                onChange={handleChange}
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='condicaoMedica'>Possui alguma condição médica ou problema não mencionado?</label>
              <textarea
                id='condicaoMedica'
                name='condicaoMedica'
                value={formData.condicaoMedica}
                onChange={handleChange}
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='medicacao'>Toma alguma medicação?</label>
              <textarea
                id='medicacao'
                name='medicacao'
                value={formData.medicacao}
                onChange={handleChange}
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='objetivo'>Qual o seu objetivo na busca pelo Yoga?</label>
              <textarea
                id='objetivo'
                name='objetivo'
                value={formData.objetivo}
                onChange={handleChange}
                required
              />
            </div>
            <MainBtn type='submit'>Enviar</MainBtn>
          </form>
        </div>
      
      </div>
    </div>
  );
}

export default Formulario;

