import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ModalidadesOnline.css";
import MainBtn from "../MainBtn/MainBtn";
import logocircular from "../../assets/imgs/logocircular1.png";
import online from "../../assets/imgs/online.jpg";
import online2 from "../../assets/imgs/online2.jpg";
import online3 from "../../assets/imgs/online3.jpg";
import TerapiaRestaurativa from "../terapiaresaturativa/TerapiaRestaurativa";
import MahaLilah from "../mahalilah/Mahalilah";
import AulasEmpresas from "../../components/aulasempresas/AulasEmpresas";

function ModalidadesOnline() {
  const [currentImg, setCurrentImg] = useState(online);
  const [selectedModality, setSelectedModality] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const images = [online, online2, online3];

    const interval = setInterval(() => {
      const currentIndex = images.indexOf(currentImg);
      const nextIndex = (currentIndex + 1) % images.length;

      setCurrentImg(images[nextIndex]);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentImg]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleModalityChange = (modality) => {
    scrollToTop();
    setSelectedModality(modality);
    // Handle navigation if needed, e.g., navigate("/path")
  };

  return (
    <div className="modalidadesonline-wrapper">
      <div className="modalidadesonline-container">
        <div className="modalidadesonline-content">
          <div className="projetos-flex">
            <h1>Projetos</h1>
          </div>
          <br />
          <br />

          <div className="textos-modalidalidades">
            <div className="grid-container">
              <div className="grid-item">
                <h4>Terapia Restaurativa</h4>
                <br />
                <p>
                  Um acompanhamento terapêutico com foco em ‘Restaurar’ os
                  pilares do bem estar, que engloba uma boa nutrição, atividade
                  física, bons relacionamentos interpessoais, familiares e
                  sociais, além do gerenciamento das emoções e dos pensamentos
                  através do Yoga Restaurativo, Reiki e Aromaterapia e
                  alimentação natural. Maha Lilah complementa essa abordagem ao
                  promover introspecção e crescimento pessoal.
                </p>
                
              </div>
              <div className="grid-item">
                <h4>Yoga em empresas, retiros e imersões.</h4>
                <br />
                <p>
                  As sessões de Yoga ajudam a reduzir o estresse, aumentar a
                  produtividade e promover um ambiente de trabalho saudável. Com
                  posturas e técnicas de respiração, os participantes relaxam a
                  mente e o corpo, melhorando a colaboração entre os colegas.
                </p>
               
              </div>

              
            </div>
          </div>

          <div className="modalidadesonline-img">
            <div className="online">
              <img src={currentImg} alt="Online" />
            </div>
          </div>

          {/* Conditional rendering based on selected modality */}
          <div className="modalidades-content">
            {selectedModality === "terapia" && <TerapiaRestaurativa />}
            {selectedModality === "mahalilah" && <MahaLilah />}
            {selectedModality === "aulas" && <AulasEmpresas />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalidadesOnline;
