import React from 'react';
import './pdd.css';
import MainBtn from '../MainBtn/MainBtn';
import marilia from "../../assets/imgs/mariliainicio.png";
import { InstagramEmbed } from "react-social-media-embed";

function Pdd() {
  return (
    <div className="instaFeed-wrapper">
    <div className="instaFeed-container">
      <div className="instaFeed-content">
        <div>
          <InstagramEmbed
            url="https://www.instagram.com/p/ClRMZHBr-Rt/?img_index=1"
            width={328} 
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/CUdNGDRl-8_/"
            width={328}
          />
         
        </div>
        
      </div>
    </div>
  </div>
  );
}

export default Pdd;
