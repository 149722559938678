import React, { useEffect, useState } from "react";
import "./Hero.css";
import MainBtn from "../MainBtn/MainBtn";

import hero10 from "../../assets/imgs/hero10.jpg";
import hero9 from "../../assets/imgs/hero9.jpg";
import hero6 from "../../assets/imgs/hero4.jpg";

const images = [hero10, hero9, hero6];

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      className="hero-wrapper"
      style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
    >
      <div className="hero-overlay"></div>
      <div className="hero-container">
        <div className="hero-content">
          <div>
            
            
          
          </div>
       
        </div>

        <div className="hero-img"></div>
      </div>
    </div>
  );
};

export default Hero;
