import React, { useState, useEffect } from 'react';
import './Cz.css';
import { InstagramEmbed } from "react-social-media-embed";

function Cz() {
  const [loading, setLoading] = useState(true);

  const urls = [
    "https://www.instagram.com/p/DA9FJ-Cyj_H/",
    "https://www.instagram.com/p/DBt4s9gSvl0/",
    "https://www.instagram.com/p/DAjictnSr6g/",
    "https://www.instagram.com/p/DBzBbkQJ58T/"
  ];

  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 1000); // Simulando carregamento
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="instaFeed-wrapper2">
      <div className="instaFeed-container2">
        {loading ? (
          <div>Loading...</div> // Você pode substituir por um spinner ou skeleton
        ) : (
          <div className="instaFeed-content2">
            {urls.map((url, index) => (
              <InstagramEmbed key={index} url={url} width={328} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Cz;
