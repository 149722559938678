import React from 'react';
import './link.css'; // Certifique-se de criar um arquivo CSS para estilos

const LinkTree = () => {
  const links = [
    { name: 'Meu Instagram', url: 'https://www.instagram.com/mariliamariano_/' },
    { name: 'Instagram Yoga Parque das Dunas', url: 'https://www.instagram.com/yogaparquedasdunas/' },
    { name: 'Contato', url: "https://wa.me/5584996130349" },
    { name: 'Meu site', url: 'https://www.mariliamariano.com.br' },
  ];

  return (
    <div className="linktree-container">
        
      <h1>Links Rápidos</h1>
      <div className="links">
        {links.map((link, index) => (
          <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className="link-item">
            {link.name}
          </a>
        ))}
      </div>
    </div>
  );
};

export default LinkTree;
