import React, { useEffect } from 'react';
import './Sala.css';
import MainBtn from '../MainBtn/MainBtn';
import Formulario from '../formulario/Formulario';
import Cz from '../cz/Cz'

function Sala() {
  useEffect(() => {
    // Função para rolar para o topo
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='sala-wrapper'>
      <div className='sala-container'>
        <div className='sala-content'>
          <br />
          <br />
          <br />
          <h2> Yoga Aromático
          </h2>
          <p>
          O Yoga Aromático integra posturas de yoga com óleos essenciais, promovendo relaxamento e elevação do humor. Essa prática busca equilibrar mente, corpo e espírito, proporcionando momentos de autocuidado e bem-estar emocional.
            <br /><br />
            <p>Acompanhe minhas redes sociais para saber dos próximos encontros.</p>
            <strong>Duração:</strong> 1h 30min<br />
            <strong>Dias:</strong> Consultar disponibilidade.<br />
            
           
          </p>
          <div>
       
          </div>
         
          <div className='tenho-interesse-form'>
            <Formulario />
          </div>
          <Cz/>
        </div>
      </div>
    </div>
  );
}

export default Sala;
