import React from "react";
import "./Mapa2.css";
function Mapa2() {
  return (
    <div className="mapa2-container">
      <iframe
        className="mapa2"
        title="Localização"
        width="700"
        height="300"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15877.136840029243!2d-35.2203095!3d-5.8154785!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7b255ee3f4468e3%3A0x72c187658ea870d1!2sCasa%20Zen!5e0!3m2!1spt-BR!2sbr!4v1728143245709!5m2!1spt-BR!2sbr"
        referrerpolicy="no-referrer-when-downgrade">
      </iframe>
      
    </div>
  );
}





export default Mapa2;
